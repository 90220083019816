export default {
  name: {
    type: String,
    default: '',
  },
  titleWords: {
    type: Array,
    required: true,
  },
  amount: {
    type: Number,
    required: true,
  },
  messageLetters: {
    type: Array,
    default: null,
  },
  image: {
    type: String,
    required: true,
  },
  fontFamily: {
    type: String,
    required: true,
  },
  fontSize: {
    type: String,
    required: true,
  },
  fontWeight: {
    type: Number,
    required: true,
  },
  textColor: {
    type: String,
    required: true,
  },
  textHighlightColor: {
    type: String,
    required: true,
  },
  textDelay: {
    type: String,
    required: true,
  },
}
