<template>
  <div>
    <component :is="component" />
  </div>
</template>
<script>
import HomeFirebase from '@/views/HomeFirebase.vue'
import HomeWebSocket from '@/views/HomeWebSocket.vue'

export default {
  components: {
    HomeFirebase,
    HomeWebSocket,
  },
  data() {
    return {
      roles: [],
    }
  },
  computed: {
    canUseNewStreamAlert() {
      return true
    },
    component() {
      if (!this.roles || !this.roles.length) {
        return null
      }

      return this.canUseNewStreamAlert
        ? 'HomeWebSocket'
        : 'HomeFirebase'
    },
  },
  created() {
    this.userId = this.$route.params.userId

    fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}`)
      .then(response => response.json())
      .then(data => {
        this.roles = data.data.roles
      })
      .catch(error => {
        console.error(error)
      })
  },
}
</script>

<style>
</style>
