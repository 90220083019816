<template>
  <div v-show="elementVisible">
    <div
      v-if="position == 'bottom'"
      id="container"
    >
      <img :src="bgImage">
      <p
        v-if="donation.name != ''"
        id="user_name"
        class="outline"
      >
        {{ donation.name }}
      </p>
      <p
        v-else
        id="user_name"
        class="outline"
      >
        Anonymous
      </p>
      <p
        id="user_amt"
        class="outline"
      >
        <span style="color: #fff">Donated</span> ${{ donation.amount }}
      </p>
      <p
        id="user_msg"
        class="outline"
      >
        {{ donation.message }}
      </p>

      <button
        v-show="false"
        ref="myBtn"
        @click="playNote"
      >
        Play
      </button>
    </div>
    <div
      v-else-if="position == 'right'"
      class="flex-container"
    >
      <div class="flex-child">
        <img :src="bgImage">
      </div>

      <div class="flex-child">
        <p
          v-if="donation.name != ''"
          id="user_name"
          class="outline"
        >
          {{ donation.name }}
        </p>
        <p
          v-else
          id="user_name"
          class="outline"
        >
          Anonymous
        </p>
        <p
          id="user_amt"
          class="outline"
        >
          <span style="color: #fff">Donated</span> ${{ donation.amount }}
        </p>

        <button
          v-show="false"
          ref="myBtn"
          @click="playNote"
        >
          Play
        </button>
        <p
          id="user_msg"
          class="outline"
        >
          {{ donation.message }}
        </p>
      </div>
    </div>
    <div
      v-else
      id="container"
      :style="`background-position:${position}; background-repeat:no-repeat; background-size:contain; background-image: url(${bgImage}); `"
    >
      <p
        v-if="donation.name != ''"
        id="user_name"
        class="outline"
      >
        {{ donation.name }}
      </p>
      <p
        v-else
        id="user_name"
        class="outline"
      >
        Anonymous
      </p>
      <p
        id="user_amt"
        class="outline"
      >
        <span style="color: #fff">Donated</span> ${{ donation.amount }}
      </p>

      <button
        v-show="false"
        ref="myBtn"
        @click="playNote"
      >
        Play
      </button>
      <p
        id="user_msg"
        class="outline"
      >
        {{ donation.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { db } from '@/plugins/db'

const defaultSound = require('@/assets/audios/coin.mp3')

const bg = require('@/assets/images/default-img.png')

export default {
  props: ['id', 'counted'],
  data() {
    return {
      userMedia: { alertSounds: [], alertImages: [] },
      donation: {},
      donationList: [],
      elementVisible: false,
      userId: null,
      someSound: defaultSound,
      bgImage: bg,
      countedData: this.counted,
      position: 'center',
      defaultStyle: {
        background: `url(${bg})`,
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'center',
      },
      newStyle: null,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.$bind('donation', db.collection(process.env.VUE_APP_FIREBASE_COLLECTION).doc(id)).then(() => {
          const elem = this.$refs.myBtn
          setTimeout(
            () => {
              this.elementVisible = true
              elem.click()
            },
            (this.countedData *= 5000),
          )
          setTimeout(
            () => {
              this.elementVisible = false
              this.$firestoreRefs.donation.delete()
            },
            this.countedData + 5000,
          )
        })
      },
    },
  },
  created() {
    this.userId = this.$route.params.userId
    this.loadUserSetting()
  },

  methods: {
    playNote() {
      const note = !this.userMedia.alertSounds?.length
        ? new Audio(this.someSound)
        : new Audio(this.userMedia.alertSounds[0])
      note.volume = 0.5
      note.addEventListener('canplaythrough', () => {
        note.play()
        setTimeout(() => {
          note.pause()
        }, 5500)
      })
    },
    async loadUserSetting() {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/user-donation-setting`)
        .then(res => res.json())
        .then(data => {
          if (data.data) {
            this.userMedia = data.data
            this.newStyle = {
              background:
                `url(${
                  this.userMedia.donationAlertImages
                    ? this.userMedia.donationAlertImages.url
                    : bg
                })`,
              'background-repeat': 'no-repeat',
              'background-size': 'contain',
              'background-position': 'center',
            }
            this.bgImage = this.userMedia.donationAlertImages
              ? this.userMedia.donationAlertImages.url
              : bg
            this.position = this.userMedia.textLayout
              ? this.userMedia.textLayout
              : 'in_front'
          }
        })
        .catch(err => console.error(err.message))
    },
  },
}
</script>
