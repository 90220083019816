<template>
  <div
    class="layout-standard"
    :style="{ 'font-family': goalSettings.fontFamily }"
  >
    <div
      class="title"
      :style="{ color: goalSettings.textColor }"
    >
      {{ title }}
    </div>

    <div
      class="bar"
      :style="{
        color: goalSettings.barTextColor,
        backgroundColor: goalSettings.barBackgroundColor,
        minHeight: `${goalSettings.barThickness}px`,
      }"
    >
      <div class="progress">
        ${{ goalSettings.currentAmount | number({ minimumFractionDigits: 2 }) }} ({{ progressPercentage }}%)
      </div>
      <div
        class="bar-progress"
        :style="{
          width: `${widthPercentage}%`,
          backgroundColor: goalSettings.barColor,
        }"
      />
    </div>

    <div
      class="footer"
      :style="{ color: goalSettings.textColor }"
    >
      <div class="min-amount">
        $0.00
      </div>
      <div class="target-amount">
        ${{ goalSettings.targetAmount | number({ minimumFractionDigits: 2 }) }}
      </div>
    </div>
  </div>
</template>

<script>
import props from '@/props/goal-overlay'

export default {
  props,
  data() {
    return {
      goalSettings: {
        title: this.title,
        targetAmount: this.targetAmount,
        currentAmount: this.currentAmount,
        fontFamily: this.fontFamily,
        barBackgroundColor: this.barBackgroundColor,
        barColor: this.barColor,
        barTextColor: this.barTextColor,
        textColor: this.textColor,
        barThickness: this.barThickness,
      },
    }
  },
  computed: {
    progressPercentage() {
      // prevent divide by 0 or 0 progress
      if (!this.goalSettings.targetAmount || !this.goalSettings.currentAmount) {
        return 0
      }
      const percentage = (this.goalSettings.currentAmount / this.goalSettings.targetAmount) * 100
      return Math.round(percentage)
    },
    widthPercentage() {
      // prevent divide by 0 or 0 progress
      if (!this.goalSettings.targetAmount || !this.goalSettings.currentAmount) {
        return 0
      }

      const percentage = (this.goalSettings.currentAmount / this.goalSettings.targetAmount) * 100

      // if exceeded
      if (percentage > 100) {
        return 100
      }

      return Math.round(percentage)
    },
  },
  watch: {
    $props: {
      handler(newProps) {
        console.log('update props', newProps)
        this.goalSettings = { ...newProps }
      },
      deep: true,
    },
  },
}
</script>

<style>
.layout-standard {
  font-size: 20px;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
}

.layout-standard .title {
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  text-shadow: 0 0 0.05em rgba(0, 0, 0, 0.75);
}

.layout-standard .bar {
  position: relative;
  width: 100%;
  box-shadow: 0 0 0.05em rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-standard .bar .bar-progress {
  content: '';
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  transition: width 1s;
}

.layout-standard .progress {
  z-index: 10;
  position: relative;
  font-size: 1.2em;
}

.layout-standard .footer {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  text-shadow: 0 0 0.05em rgba(0, 0, 0, 0.75);
}
</style>
