<template>
  <div id="app">
    <Donation
      v-for="(b, index) in donations"
      :id="b.id"
      :key="b.id"
      :counted="index + 1"
    />
  </div>
</template>
<script>
import { db } from '@/plugins/db'
import Donation from '@/components/DonationFirebase.vue'

export default {
  name: 'Home',
  components: {
    Donation,
  },
  data() {
    return {
      donations: [],
      userId: null,
    }
  },
  mounted() {
    this.userId = this.$route.params.userId

    const dateNow = new Date()
    dateNow.setMinutes(dateNow.getMinutes() - 20)

    this.$bind(
      'donations',
      db.collection(process.env.VUE_APP_FIREBASE_COLLECTION)
        .where('user_id', '==', this.userId),
    )
  },
}
</script>
