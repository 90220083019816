<template>
  <div
    class="layout-condensed"
    :style="{ 'font-family': goalSettings.fontFamily }"
  >
    <div
      class="bar"
      :style="{
        color: goalSettings.barTextColor,
        backgroundColor: goalSettings.barBackgroundColor,
        minHeight: `${goalSettings.barThickness}px`,
      }"
    >
      <div class="progress">
        ${{ goalSettings.currentAmount | number({ minimumFractionDigits: 2 }) }} ({{ progressPercentage }}%)
      </div>

      <div class="sides">
        <div class="title">
          {{ goalSettings.title }}
        </div>
        <div class="target-amount">
          ${{ goalSettings.targetAmount | number({ minimumFractionDigits: 2 }) }}
        </div>
      </div>

      <div
        class="bar-progress"
        :style="{
          width: `${widthPercentage}%`,
          backgroundColor: goalSettings.barColor,
        }"
      />
    </div>
  </div>
</template>

<script>
import props from '@/props/goal-overlay'

export default {
  props,
  data() {
    return {
      goalSettings: {
        title: this.title,
        targetAmount: this.targetAmount,
        currentAmount: this.currentAmount,
        fontFamily: this.fontFamily,
        barBackgroundColor: this.barBackgroundColor,
        barColor: this.barColor,
        barTextColor: this.barTextColor,
        textColor: this.textColor,
        barThickness: this.barThickness,
      },
    }
  },
  computed: {
    progressPercentage() {
      // prevent divide by 0 or 0 progress
      if (!this.goalSettings.targetAmount || !this.goalSettings.currentAmount) {
        return 0
      }
      const percentage = (this.goalSettings.currentAmount / this.goalSettings.targetAmount) * 100

      return Math.round(percentage)
    },
    widthPercentage() {
      // prevent divide by 0 or 0 progress
      if (!this.goalSettings.targetAmount || !this.goalSettings.currentAmount) {
        return 0
      }

      const percentage = (this.goalSettings.currentAmount / this.goalSettings.targetAmount) * 100

      // if exceeded
      if (percentage > 100) {
        return 100
      }

      return Math.round(percentage)
    },
  },
  watch: {
    $props: {
      handler(newProps) {
        console.log('newProps', newProps)
        this.goalSettings = { ...newProps }
      },
      deep: true,
    },
  },
}
</script>

<style>
.layout-condensed {
  font-size: 20px;
  font-weight: 600;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.layout-condensed .title {
  max-width: 38%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.layout-condensed .bar {
  position: relative;
  width: 100%;
  box-shadow: 0 0 0.05em rgba(0, 0, 0, 0.75);
  height: 100%;
  display: flex;
}

.layout-condensed .bar .bar-progress {
  content: '';
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.layout-condensed .progress {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.2em;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.layout-condensed .sides {
  z-index: 11;
  position: relative;
  display: flex;
  width: calc(100% - 32px);
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
</style>
