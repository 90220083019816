var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-in-front",style:({ backgroundImage: 'url(' + _vm.image + ')' })},[_c('div',{staticClass:"text",class:{'fade-in show': _vm.textDelay !== '0s'},style:({
      fontSize: _vm.fontSize,
      color: _vm.textColor,
      animationDelay: _vm.textDelay,
      animationDuration: _vm.textDelay !== '0s' ? '0.175s' : '0s',
      opacity: _vm.textDelay !== '0s' ? 0 : undefined,
    })},[_c('div',[_c('div',{staticClass:"title",style:({
          fontFamily: _vm.fontFamily,
          fontWeight: _vm.fontWeight,
        })},_vm._l((_vm.titleWords),function(item,index){return _c('span',{key:("title-word-" + index)},_vm._l((item.letters),function(letter,letterIndex){return _c('span',{key:("title-letter-" + letterIndex),class:letter.textAnimation,style:({color: letter.textColor})},[_vm._v(_vm._s(letter.value))])}),0)}),0),(_vm.messageLetters)?_c('div',{staticClass:"message",style:({
          fontFamily: _vm.fontFamily,
        })},_vm._l((_vm.messageLetters),function(item,index){return _c('span',{key:("message-letter-" + index)},[_vm._v(_vm._s(item.value))])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }