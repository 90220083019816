import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import DonationGoal from '@/views/DonationGoal.vue'
import MediaShare from '@/views/MediaShare.vue'
import QrCode from '@/views/QrCode.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/:userId',
      component: Home,
    },
    {
      path: '/:userId/donation-goal',
      component: DonationGoal,
    },
    {
      path: '/:userId/media-share',
      component: MediaShare,
    },
    {
      path: '/:userId/qr-code',
      component: QrCode,
    },
  ],
})
