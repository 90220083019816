<template>
  <div
    ref="donation"
    class="donation"
    :class="customClass"
  >
    <component
      :is="component"
      :title-words="titleWords"
      :name="donorName"
      :amount="donorAmount"
      :message-letters="messageLetters"
      :image="image"
      :font-family="fontFamily"
      :font-size="fontSize"
      :font-weight="fontWeight"
      :text-color="textColor"
      :text-highlight-color="textHighlightColor"
      :text-delay="textDelay"
    />
  </div>
</template>

<script>
import voicesJSON from '@/assets/json/voices.json'

import defaultSound from '@/assets/audios/coin.mp3'
import defaultImage from '@/assets/images/default-img.png'

import DonationInFront from '@/components/DonationInFront.vue'
import DonationRight from '@/components/DonationRight.vue'
import DonationBottom from '@/components/DonationBottom.vue'

export default {
  components: {
    DonationInFront,
    DonationRight,
    DonationBottom,
  },
  props: {
    donation: {
      type: Object,
      required: true,
    },
    textToSpeech: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      audio: null,
      audioTts: null,

      audioEnded: false,
      alertHidden: false,

      // bottom, right, in_front(default)
      textLayout: this.donation.settings.textLayout || 'in_front',
      donationAlertImages: this.donation.settings.donationAlertImages,
      displayLocalCurrency: this.donation.settings.displayLocalCurrency || false,
      donationAlertSounds: this.donation.settings.donationAlertSounds,
      soundVolume: this.donation.settings.soundVolume || 50, // from 0 - 100
      titleTemplate: this.donation.settings.messageTemplate || '{name} donated {amount}!',

      alertAnimation: this.donation.settings.alertAnimation || { in: 'fade-in', out: 'fade-out' },
      alertDuration: this.donation.settings.alertDuration || 9, // in seconds

      fontFamily: this.donation.settings.fontSetting
        ? this.donation.settings.fontSetting.fontType
        : 'roboto',
      fontWeight: this.donation.settings.fontSetting
        ? this.donation.settings.fontSetting.fontWeight
        : 800,
      textColor: this.donation.settings.fontSetting
        ? this.donation.settings.fontSetting.textBaseColor
        : '#FFFFFF',
      textHighlightColor: this.donation.settings.fontSetting
        ? this.donation.settings.fontSetting.textHighlightColor
        : '#23A87F',
      textDelay: `${this.donation.settings.alertTextDelay || 0}s`,
      textAnimation: this.donation.settings.textAnimation
        ? `animated-letter ${this.donation.settings.textAnimation}`
        : null,
      ttsSettings: this.textToSpeech,
      donorName: this.donation.donorName || 'Anonymous',
      donorAmount: parseFloat(this.donation.donationAmount),
      donorAmountLocal: parseFloat(this.donation.donationAmountLocalCurrency),
      donationLocalCurrency: this.donation.donationLocalCurrency,
      donorMessage: this.donation.donorMessage,
    }
  },
  computed: {
    ttsMinDonationToRead() {
      return this.ttsSettings && this.ttsSettings.minDonationToRead !== undefined
        ? this.ttsSettings.minDonationToRead
        : 0
    },
    ttsVoice() {
      if (this.ttsSettings && this.ttsSettings.voice) {
        if (typeof this.ttsSettings.voice === 'string') {
          return this.getVoiceData(this.ttsSettings.voice)
        } if (typeof this.ttsSettings.voice === 'object' && this.ttsSettings.voice.aliasName) {
          return this.getVoiceData(this.ttsSettings.voice.aliasName)
        }
      }
      return voicesJSON.find(item => item.aliasName === 'James')
    },
    ttsVolume() {
      return this.ttsSettings && this.ttsSettings.volume !== undefined
        ? this.ttsSettings.volume
        : 50
    },
    ttsIncludeTemplate() {
      return this.ttsSettings && this.ttsSettings.includeTemplate !== undefined
        ? this.ttsSettings.includeTemplate
        : false
    },
    component() {
      switch (this.textLayout) {
        case 'in_front': return 'DonationInFront'
        case 'right': return 'DonationRight'
        case 'bottom': return 'DonationBottom'
        default: return 'DonationInFront'
      }
    },
    customClass() {
      const customClass = [
        this.alertAnimation.in,
        this.alertAnimation.out,
      ]

      if (this.show) {
        customClass.push('show')
      }

      return customClass
    },
    image() {
      return this.donationAlertImages
        ? this.donationAlertImages.url
        : defaultImage
    },
    sound() {
      return this.donationAlertSounds
        ? this.donationAlertSounds.url
        : defaultSound
    },
    titleArray() {
      if (!this.titleTemplate) {
        return null
      }

      const nameIndex = this.titleTemplate.indexOf('{name}')
      const amountIndex = this.titleTemplate.indexOf('{amount}')

      const title = this.titleTemplate.split(/{name}|{amount}/)

      if (nameIndex !== -1) {
        // if name first or does not have amount
        if (nameIndex < amountIndex || amountIndex === -1) {
          title.splice(1, 0, '{name}')
        } else if (amountIndex !== -1) {
          title.splice(3, 0, '{name}')
        }
      }

      if (amountIndex !== -1) {
        // if amount first or does not have name
        if (amountIndex < nameIndex || nameIndex === -1) {
          title.splice(1, 0, '{amount}')
        } else if (nameIndex !== -1) {
          title.splice(3, 0, '{amount}')
        }
      }

      return title.filter(item => item !== '')
    },
    titleWords() {
      const words = []

      console.log('displayLocalCurrency', this.displayLocalCurrency)
      console.log('local amount', this.donorAmountLocal)
      console.log('local currency', this.donationLocalCurrency)

      if (!this.titleArray) {
        return null
      }

      for (let index = 0; index < this.titleArray.length; index += 1) {
        const letters = []
        let word = this.titleArray[index]
        let textColor
        let textAnimation

        if (word === '{name}') {
          word = this.donorName
          textColor = this.textHighlightColor
          textAnimation = this.textAnimation
        } else if (word === '{amount}') {
          word = this.formatCurrency(this.donorAmount, 'US$')
          if (this.displayLocalCurrency && this.donationLocalCurrency) {
            word = this.formatCurrency(this.donorAmountLocal, this.donationLocalCurrency)
          }

          textColor = this.textHighlightColor
          textAnimation = this.textAnimation
        }

        for (let wordIndex = 0; wordIndex < word.length; wordIndex += 1) {
          const letter = word[wordIndex]

          letters.push({
            value: letter,
            textColor,
            textAnimation,
          })
        }

        words.push({ letters })
      }

      return words
    },
    messageLetters() {
      const letters = []

      if (!this.donorMessage) {
        return null
      }

      for (let index = 0; index < this.donorMessage.length; index += 1) {
        const letter = this.donorMessage[index]

        letters.push({
          value: letter,
          textColor: this.textColor,
          textAnimation: this.textAnimation,
        })
      }

      return letters
    },
    fontSize() {
      // use vmin for bottom layout
      const unit = this.textLayout === 'bottom' ? 'vmin' : 'vmax'
      const baseFontSize = 40 // 40px default size for user
      let multiplier = 4

      switch (this.textLayout) {
        case 'bottom': multiplier = 6; break
        case 'in_front': multiplier = 6; break
        case 'right': multiplier = 4; break
        default: multiplier = 4
      }

      if (this.donation.settings.fontSetting) {
        return `${multiplier}${unit}`
      }

      const fontSize = this.donation.settings.fontSetting && this.donation.settings.fontSetting.fontSize
        ? parseInt(this.donation.settings.fontSetting.fontSize.replace('px', ''), 10)
        : 30

      return `${(fontSize / baseFontSize) * multiplier}${unit}`
    },
  },
  watch: {
    show(val) {
      // remove alert when this.show = false and alert is not hidden
      // most likely because of new donation has been pushed
      // when the current donation's duration has not finished yet
      if (!val && !this.alertHidden) {
        this.hideAlert()
      }
    },
    donation: {
      handler(newVal) {
        const donationSettings = newVal.settings
        this.ttsSettings = donationSettings.textToSpeechEnabled ? donationSettings.textToSpeech : undefined
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // play audio immediately
    // works after user interact with the screen (at least once)
    this.playSound()

    // hide alert after reach the duration limit (ms)
    setTimeout(this.hideAlert, this.alertDuration * 1000)
  },
  methods: {
    playSound() {
      try {
        // Set audio
        this.audio = new Audio(this.sound)

        // Set volume
        this.audio.volume = this.soundVolume
          ? this.soundVolume / 100 // from 0 - 1
          : 0

        // play audio
        this.audio.play()
          .catch(() => {
            this.$emit('pushLog', 'Can\'t play audio. Make sure to interact with the UI first.')
            console.error('can\'t play audio. please interact with the UI first.')
            this.playTts()
          })

        this.audio.addEventListener('ended', () => {
          try {
            this.audio.currentTime = 0
          } catch (e) {
            // do nothing
          }

          setTimeout(() => {
            // play tts after audio ended
            // works after user interact with the screen (at least once)
            this.playTts()
          }, 500)
        })
      } catch (e) {
        // auto play tts audio if sound not available or error
        this.playTts()
      }
    },
    playTts() {
      try {
        // do not play audio if tts not enabled
        // or donation amount less than minimum to read
        console.log('ttsSettings', this.ttsSettings)

        if (!this.ttsSettings || this.donorAmount < this.ttsMinDonationToRead) {
          this.onAudioEnded()
          return
        }
        console.log('ttsVoice', this.ttsVoice)

        const voice = this.ttsVoice.aliasName
        const { languageCode } = this.ttsVoice
        const volume = this.ttsVolume

        const text = this.getTtsText(languageCode)
        console.log('getTtsText', text)

        // do not play audio if there is no text to speech
        if (!text) {
          this.onAudioEnded()
          return
        }

        // always request at volume 100, and change the volume on client side
        const data = { text, voice, volume: 100 }
        console.log('playTts data', data)

        this.$http
          .post(`${process.env.VUE_APP_API_BASE_URL}/v1/media/text-to-speech`, data)
          .then(res => this.playTtsAudio(res.data.data.audioContent, volume))
          .catch(error => {
            console.log('error', error)

            this.onAudioEnded()
          })
      } catch (e) {
        // end audio if error
        this.onAudioEnded()
      }
    },
    getTtsText(languageCode) {
      let text = this.donorMessage
      console.log('ttsIncludeTemplate', this.ttsIncludeTemplate)

      if (this.ttsIncludeTemplate) {
        let template = this.titleTemplate
        template = template.replace(/{name}/g, this.donorName)

        if (this.displayLocalCurrency && this.donationLocalCurrency) {
          const amount = this.donorAmountLocal.toLocaleString(languageCode)
          template = template.replace(/{amount}/g, `${this.donationLocalCurrency} ${amount}`)
        } else {
          const amount = this.donorAmount.toLocaleString(languageCode)
          template = template.replace(/{amount}/g, `US$ ${amount}`)
        }
        console.log('template', template)
        text = text ? `${template}. ${text}` : template
      }

      return text
    },
    playTtsAudio(audio, volume) {
      this.audioTts = new Audio(`data:audio/mp3;base64,${audio}`)
      this.audioTts.volume = volume / 100
      this.audioTts.play()
        .catch(() => {
          this.$emit('pushLog', 'Can\'t play audio. Make sure to interact with the UI first.')
          console.error('can\'t play audio. please interact with the UI first.')
          this.onAudioEnded()
        })

      this.audioTts.addEventListener('ended', () => {
        this.onAudioEnded()

        try {
          this.audioTts.currentTime = 0
        } catch (e) {
          // do nothing
        }
      })
    },
    getVoiceData(aliasName) {
      return voicesJSON.find(item => item.aliasName === aliasName)
    },
    hideAlert() {
      this.$emit('pushLog', 'Finish showing stream alert.')
      console.info('hidden')

      // check if the component still exist
      if (!this.$refs.donation) {
        return
      }

      this.$refs.donation.classList.remove('show')

      this.alertHidden = true

      if (this.audioEnded) {
        this.finish()
      }
    },
    onAudioEnded() {
      this.$emit('pushLog', 'Finish playing stream alert audio.')
      console.info('audio ended')
      this.audioEnded = true

      if (this.alertHidden) {
        this.finish()
      }
    },
    finish() {
      setTimeout(() => {
        console.info('finish')
        this.$emit('finish')
      }, 500)
    },
    formatCurrency(amount, currency) {
      return `${currency} ${this.$options.filters.number(amount, { minimumFractionDigits: 2 })}`
    },
  },
}
</script>

<style>
.donation {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  animation-duration: 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
</style>
