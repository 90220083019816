export default {
  title: {
    type: String,
    required: true,
  },
  targetAmount: {
    type: Number,
    required: true,
  },
  currentAmount: {
    type: Number,
    required: true,
  },
  fontFamily: {
    type: String,
    required: true,
  },
  barBackgroundColor: {
    type: String,
    required: true,
  },
  barColor: {
    type: String,
    required: true,
  },
  barTextColor: {
    type: String,
    required: true,
  },
  textColor: {
    type: String,
    required: true,
  },
  barThickness: {
    type: Number,
    required: true,
  },
}
