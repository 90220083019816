<template>
  <div class="layout-right">
    <div class="row">
      <!-- Image -->
      <img
        :src="image"
        alt="Image"
      >

      <!-- Text -->
      <div
        class="text"
        :class="{'fade-in show': textDelay !== '0s'}"
        :style="{
          fontSize,
          color: textColor,
          animationDelay: textDelay,
          animationDuration: textDelay !== '0s' ? '0.175s' : '0s',
          opacity: textDelay !== '0s' ? 0 : undefined,
        }"
      >
        <!-- Title -->
        <div
          class="title"
          :style="{
            fontFamily,
            fontWeight,
          }"
        >
          <span
            v-for="(item, index) in titleWords"
            :key="`title-word-${index}`"
          >
            <span
              v-for="(letter, letterIndex) in item.letters"
              :key="`title-letter-${letterIndex}`"
              :style="{color: letter.textColor}"
              :class="letter.textAnimation"
            >{{ letter.value }}</span></span>
        </div>

        <!-- Message -->
        <div
          v-if="messageLetters"
          class="message"
          :style="{
            fontFamily,
          }"
        >
          <span
            v-for="(item, index) in messageLetters"
            :key="`message-letter-${index}`"
          >{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import props from '@/props/donation-layout'

export default {
  props,
}
</script>

<style>
.layout-right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-right .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.layout-right .text {
  flex: 0 0 auto;
  width: auto;
  max-width: 67%;
  text-align: center;
  height: 100%;
  text-shadow: 0.05em 0 0.125em rgba(0, 0, 0, 0.5);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  white-space: break-spaces;
}

.layout-right img {
  flex: 0 0 33%;
  max-width: 33%;
  max-height: 100vh;
  width: 33vmax;
  object-fit: contain;
}

.layout-right .title > span {
  display: inline-block;
}

.layout-right .message {
  font-family: sans-serif;
  margin-top: 0.2em;
  font-size: 0.6em; /* n% of the user's selected size */
}
</style>
