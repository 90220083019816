import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// Get a Firestore instance
export const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyAVNwfvu3haYSiNr_P1TjHGHFVW3LUj-TI',
    authDomain: 'gank-global.firebaseapp.com',
    databaseURL: 'https://gank-global.firebaseio.com',
    projectId: 'gank-global',
    storageBucket: 'gank-global.appspot.com',
    messagingSenderId: '854677098451',
    appId: '1:854677098451:web:0125f87a5f6583bf5c2926',
  })
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })
