<template>
  <div>
    <div
      v-if="isDebugMode"
      class="debug-container"
    >
      <div
        v-for="(log, index) in logs"
        :key="`log-${index}`"
      >
        <span>{{ log.id }}.</span><span>{{ log.message }}</span>
      </div>
      <div><span>[DEBUG MODE]</span></div>
    </div>
    <div
      v-if="player && isLocal"
    >
      {{ realProgress }}, {{ progress }}, {{ progressPercentage }}%
    </div>
    <div
      v-if="videoId && isLocal"
    >
      Video id: {{ videoId }}
    </div>
    <div
      v-if="isLocal && queueData && queueData.id"
    >
      Queue id: {{ queueData.id }}
    </div>
    <div
      v-if="isLocal"
    >
      {{ status }}
    </div>
    <div
      v-if="player && isLocal"
    >
      Show video = {{ showPlayer ? 'Yes' : 'No' }}
    </div>
    <button
      v-if="player && isLocal"
      :disabled="!player"
      @click="togglePlay"
    >
      {{ status === PlayerState.PLAYING ? 'Pause' : 'Play' }}
    </button>
    <button
      v-if="player && isLocal"
      :disabled="!player"
      @click="changeProgress(2)"
    >
      2 seconds
    </button>
    <button
      v-if="player && isLocal"
      :disabled="!player"
      @click="changeProgress(5)"
    >
      5 seconds
    </button>
    <button
      v-if="player && isLocal"
      :disabled="!player"
      @click="changeProgress(10)"
    >
      10 seconds
    </button>
    <div
      v-show="showPlayer && showVideo"
      class="player-container"
    >
      <div class="player-frame">
        <div
          v-if="queueData && queueData.id"
          :id="`player-${queueData.id.substr(0, 6)}`"
          class="player"
        />

        <div class="progress-container">
          <div
            v-show="showPlayer && showVideo && settings && settings.mediaProgressBar"
            class="progress-bar"
            :style="{
              width: `${progressPercentage > 100 ? 100 : progressPercentage}%`,
              backgroundColor: settings && settings.progressBarColor ? settings.progressBarColor : '#ff0000',
              color: settings && settings.progressBarTextColor ? settings.progressBarTextColor : '#ffffff',
            }"
          >
            <span v-if="progressPercentage">{{ progressPercentage > 100 ? 100 : Math.round(progressPercentage) }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import YouTubePlayer from 'youtube-player'

const PlayerState = {
  UN_STARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  VIDEO_CUED: 5,
}

export default {
  data() {
    return {
      videoId: null,
      startAt: null,
      endAt: null,
      duration: null,
      isLocal: process.env.VUE_APP_DEVELOPMENT_ENV === 'local',

      userId: this.$route.params.userId,
      player: null,
      progressTimeout: null,
      realProgress: null,
      showVideo: true,
      status: null,
      volume: 50,

      queueMedia: null,
      // queueData: null,
      settings: null,
      socketMediaShare: null,

      queue: [],

      logs: [],
      isDebugMode: this.$route.query.debug === 'true',
      lastLogId: 0,

      serverDown: false,

      PlayerState,
    }
  },
  computed: {
    progress() {
      if (this.realProgress === null || this.startAt === null) {
        return 0
      }

      const progress = this.realProgress - this.startAt
      return progress < 0 ? 0 : progress
    },
    progressPercentage() {
      if (!this.progress || !this.duration) {
        return 0
      }

      return (this.progress / this.duration) * 100
    },
    showPlayer() {
      return this.player !== null
        && this.status !== null
        && this.queueData !== null
        && this.queueData.id !== null
        // do not show the video if the progress is not within the allowed time range
        && (this.status !== PlayerState.PLAYING
          || (this.realProgress === null // on earliest playing state, the progress is still empty
            || (this.realProgress >= this.startAt
              && this.realProgress <= this.endAt)))
    },
    value() {
      if (!this.queue.length) {
        return null
      }

      // first index
      return this.queue[0]
    },
    queueData() {
      if (!this.queue.length) {
        return null
      }

      // first index
      return this.queue[0].queue
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value, oldValue) {
        console.log('watch value', value, oldValue)

        // if value is the same as before
        if (value && oldValue && value.id === oldValue.id) {
          return
        }

        // destroy player before play the next video
        this.destroyPlayer()

        if (!value) {
          if (oldValue) {
            this.pushLog('No more queue.')

            // stop player if there is no more video
            this.stop()
          }

          return
        }

        this.pushLog(`Play the next media share, from ${this.queueData.donorName}, amount ${this.queueData.donationAmount}, queue id #${this.queueData.id.substr(0, 6)}`)
        console.log(`Play the next media share, from ${this.queueData.donorName}, amount ${this.queueData.donationAmount}, queue id #${this.queueData.id.substr(0, 6)}`)
        console.log('video changed')
        console.log('loadVideo watch')

        // buffer, wait until the element is ready
        setTimeout(this.initPlayer, 200)
      },
    },
  },
  mounted() {
    this.subscribeChannelMediaShare()

    this.loadMediaShare()
    this.loadQueueMedia()
  },
  beforeDestroy() {
    if (this.progressTimeout) {
      clearTimeout(this.progressTimeout)
      this.progressTimeout = null
    }
  },
  methods: {
    loadQueueMedia() {
      return this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/user-media-share-queue/all/${this.userId}`, {
        params: {
          status: 'queue',
          type: 'MEDIA_SHARE',
        },
      })
        .then(res => {
          const { data } = res.data

          if (data === null && data.length) {
            return
          }

          this.queue = data.map(item => ({
            ...JSON.parse(item.metadata),
            queue: item,
          }))
        })
        .catch(() => [])
    },
    destroyPlayer() {
      if (this.player) {
        // destroy player
        this.player.destroy()

        this.$nextTick(() => {
          const playerEl = document.querySelector('.player')

          if (playerEl && this.queueData) {
            playerEl.id = `player-${this.queueData.id.substr(0, 6)}`
          }
        })
      }
    },
    pushLog(message) {
      if (!this.isDebugMode) {
        return
      }

      this.lastLogId += 1
      this.logs.splice(0, 0, {
        id: this.lastLogId,
        message,
      })

      if (this.logs.length >= 30) {
        this.logs.pop()
      }
    },
    async subscribeChannelMediaShare() {
      const url = `${process.env.VUE_APP_SOCKET_BASE_URL}/v1/ws/users/media-share?page=media-share&source=streaming&user_id=${this.userId}`

      // Define socket and attach it to our data object
      this.socketMediaShare = await new WebSocket(url)
      this.socketMediaShare.binaryType = 'arraybuffer'

      this.socketMediaShare.onopen = () => {
        this.connectAttempt += 1
        this.serverDown = false
        this.pushLog('Successfully subscribed to the channel')
        console.info('Subscribed to media share channel')
      }

      this.socketMediaShare.onclose = () => {
        if (this.connectAttempt === 1) {
          console.info('First attempt, server down.')
          this.pushLog('Can\'t connect to the channel at the first attempt, server is down.')
          this.serverDown = true
        }

        this.pushLog('Disconnected from the channel! reconnecting..')
        console.info('Disconnected from media share channel!')

        this.subscribeChannelMediaShare()
      }

      this.socketMediaShare.onmessage = async event => {
        const eventData = JSON.parse(event.data)
        const { notificationType } = eventData

        if (notificationType === 'PING') {
          return
        }

        const { eventType } = eventData
        const { data } = eventData

        if (eventType === 'UPSERT_MEDIA_SHARE_SETTINGS') {
          console.log('upsert settings', data)
          this.settings = { ...this.settings, ...data }
          return
        }

        if ((notificationType === 'QUEUE' && eventType === 'MEDIA_SHARE')
          || (notificationType === 'REPLAY MEDIA SHARE' && eventType === 'MEDIA_SHARE')
          || (notificationType === 'MANUAL CREATE MEDIA SHARE' && eventType === 'MEDIA_SHARE')) {
          await this.pushQueue(data)
          return
        }

        if (notificationType === 'QUEUE' && eventType === 'UPDATE_QUEUE_STATUS' && data.type === 'MEDIA_SHARE'
          && data.status === 'PLAYED') {
          this.removeQueue(data)
          return
        }

        if (notificationType === 'QUEUE' && eventType === 'UPDATE_QUEUE_STATUS' && data.type === 'MEDIA_SHARE'
          && data.status === 'SKIPPED') {
          console.log('skip')
          this.forceRemoveQueue(data)
          return
        }

        if (notificationType === 'QUEUE' && eventType === 'UPDATE_QUEUE_STATUS' && data.type === 'MEDIA_SHARE'
          && data.status === 'DECLINED') {
          this.pushLog(`Decline queue id #${data.id.substr(0, 6)} from the local queue. Current total media share queue is ${this.queue.length - 1}.`)
          this.forceRemoveQueue(data)
          return
        }

        if (eventType !== 'CONTROLLER_CHANGES') {
          return
        }

        console.log('media-share-data', data)

        const { type, time, volume } = data

        if (type === 'reload_media_share') {
          this.pushLog('Reloading widget..')
          // eslint-disable-next-line no-restricted-globals
          location.reload()
          return
        }

        console.log('media-share-update', type, time, volume)
        console.log('media-share-current-status', this.status)
        this.pushLog(`Controller: ${type}`)

        if (type === 'playing' || type === 'play') {
          if (this.status === PlayerState.VIDEO_CUED) {
            this.changeProgress(time)
          }

          this.play()
        } else if (type === 'paused' || type === 'pause') {
          this.changeProgress(time)
          this.pause()
        } else if (type === 'volume') {
          this.volume = volume
          this.changeVolume(volume)
        } else if (type === 'show-video') {
          this.showVideo = true
        } else if (type === 'hide-video') {
          this.showVideo = false
        } else if (type === 'save-settings') {
          this.loadMediaShare()
        } else if (type === 'progress') {
          this.changeProgress(time)
        }
      }
    },
    pushQueue(data) {
      // skip any queue that doesn't have mediaShareUrl
      if (!data.metadata || !data.metadata.mediaShareUrl) {
        console.log('New Media Share without mediaShareUrl:', data)
        return
      }

      const item = {
        id: Math.random() * 10,
        ...data.metadata,
        queue: data.mediaQueue,
      }

      this.queue.push(item)
      this.pushLog(`Insert new media share to the local queue. Current total media share queue is ${this.queue.length}`)
      console.log('New Media Share:', item, 'Total queue:', this.queue.length)
    },
    removeQueue(data) {
      if (!this.queue.length) {
        return
      }

      const index = this.queue.findIndex(item => item.queue.id === data.id)

      if (index !== -1 && index !== 0) {
        this.queue.splice(index, 1)
      }
    },
    forceRemoveQueue(data) {
      if (!this.queue.length) {
        return
      }

      const index = this.queue.findIndex(item => item.queue.id === data.id)
      console.log('skip', index, data, this.queue)

      if (index !== -1) {
        this.queue.splice(index, 1)
      }
    },
    loadMediaShare() {
      return this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/user-media-share-settings/${this.userId}`)
        .then(res => {
          this.settings = res.data.data

          if (this.settings.volume !== null) {
            this.volume = this.settings.volume
          }
        })
    },
    initPlayer() {
      this.realProgress = null
      const playerEl = document.querySelector('.player')

      this.player = YouTubePlayer(playerEl, {
        playerVars: {
          autoplay: false,
          controls: 0,
          disablekb: 1,
          fs: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
      })

      this.player.on('stateChange', event => {
        const prevState = this.status

        if (!(this.status === PlayerState.PAUSED && event.data === PlayerState.BUFFERING)) {
          this.status = event.data
        }

        if (event.data === PlayerState.PLAYING) {
          this.pushLog('player state PLAYING')
          console.log('player state PLAYING')
        }

        if (event.data === PlayerState.PAUSED) {
          this.pushLog('player state PAUSED')
          console.log('player state PAUSED')
        }

        if (event.data === PlayerState.BUFFERING) {
          this.pushLog('player state BUFFERING')
          console.log('player state BUFFERING')

          this.player.setPlaybackQuality('small')
          this.changeVolume(this.volume)
        }

        if (event.data === PlayerState.VIDEO_CUED) {
          this.pushLog('player state VIDEO_CUED')
          console.log('player state VIDEO_CUED')
        }

        if (event.data === PlayerState.UN_STARTED) {
          this.pushLog('player state UN_STARTED')
          console.log('player state UN_STARTED')
        }

        if (event.data === PlayerState.ENDED) {
          this.pushLog('player state ENDED')
          console.log('player state ENDED')

          // if video is ended but it was playing, then mark as finished
          // below condition is required because there is weird behavior on the player,
          // which when a new video is loaded, its first state is UN_STARTED then immediately become ENDED
          if (prevState === PlayerState.PLAYING) {
            this.endVideo()
          } else {
            this.play()
          }
        }
      })

      this.player.on('ready', event => {
        console.log('ready', event)
        this.pushLog('player is READY')

        this.getProgress()
      })

      if (!this.value) {
        return
      }

      console.log('loadVideo initPlayer')
      this.pushLog('Load video after initializing player')
      this.loadVideo()
    },
    loadVideo() {
      // do not auto load video if player is not initialized or value is not set
      if (!this.player || !this.value) {
        return
      }

      this.pushLog(`Load video ${this.value.mediaShareUrl}, queue id #${this.queueData.id.substr(0, 6)}`)
      console.log('load video', this.value.mediaShareUrl)

      this.loadVideoById(this.getVideoId(this.value.mediaShareUrl), this.value.startTime, this.value.duration)
    },
    loadVideoById(videoId, startAt, duration) {
      console.log('load video by id', videoId)
      this.videoId = videoId
      this.startAt = startAt
      this.endAt = startAt + duration
      this.duration = duration

      this.player.cueVideoById({
        videoId,
        startSeconds: startAt,
        endSeconds: startAt + duration,
      })

      setTimeout(() => {
        this.play()
        this.broadcastPlayerStatus('play')
      }, 3000)
    },
    async broadcastPlayerStatus(status) {
      const time = await this.player.getCurrentTime() - this.startAt
      await this.broadcast(status, { time, volume: this.volume })
    },
    async broadcast(type, data = {}) {
      if (!this.value) {
        return
      }

      this.socketMediaShare.send(JSON.stringify({
        event_type: 'CONTROLLER_CHANGES',
        controller_change_event: {
          type,
          ...data,
        },
      }))
    },
    play() {
      // the Youtube player still keeping the last video in the memory.
      // somehow at some point the play function triggered accidentally.
      // especially if opening multiple widget URL
      if (!this.videoId || !this.player) {
        return
      }

      this.player.playVideo()
    },
    pause() {
      if (!this.player) {
        return
      }

      this.player.pauseVideo()
    },
    stop() {
      if (!this.player) {
        return
      }

      if (this.value) {
        this.pushLog(`Request stop video ${this.value.mediaShareUrl}, queue id #${this.queueData.id.substr(0, 6)}`)
      }
      console.log('request stop video')

      this.player.stopVideo()
    },
    togglePlay() {
      if (this.status === PlayerState.PLAYING) {
        this.broadcastPlayerStatus('pause')
        this.pause()
      } else {
        this.broadcastPlayerStatus('play')
        this.play()
      }
    },
    changeProgress(time) {
      console.log('changeProgress', time)
      this.realProgress = time + this.startAt
      this.player.seekTo(this.realProgress)
    },
    changeVolume(volume) {
      this.player.setVolume(volume)
    },
    getProgress() {
      this.player.getCurrentTime().then(seconds => {
        const currentProgress = Math.ceil(seconds * 100) / 100

        if (currentProgress !== this.realProgress && this.status === PlayerState.PLAYING) {
          this.realProgress = currentProgress
          console.log(currentProgress, 's')
        }

        // there is weird condition where the video playing from the beginning of the video (00:00),
        // rather than playing from the selected startAt
        // 1 is the tolerance
        if (currentProgress < this.startAt - 1 && this.status === PlayerState.PLAYING) {
          console.log('ended because the time progress is less than started at', currentProgress, this.startAt - 1)
          this.changeProgress(0)
        }

        if (currentProgress > this.endAt + 1 && this.status === PlayerState.PLAYING) {
          // this.changeProgress(this.duration)
          this.stop()
          // at this point the player doesn't assume the video has been ended.
          // so we need to trigger endVideo callback manually.
          // even the this.stop() function won't change the video status to ENDED (0)
          console.log('ended because of exceeded progress')
          this.endVideo()
        }

        this.progressTimeout = setTimeout(() => {
          this.getProgress()
        }, 200)
      })
    },
    // this function may called multiple times
    // because in certain condition, the video status doesn't change to ENDED
    // that caused this function not being called
    // and need to trigger it manually in several places
    endVideo() {
      if (!this.videoId || !this.queueData || this.queueData.type !== 'MEDIA_SHARE') {
        return
      }

      this.realProgress = null
      this.videoId = null

      this.pushLog('Change current queue status to played.')
      this.changeStatus('PLAYED')
      this.queue.splice(0, 1)
      this.pushLog(`Remove current queue. Total media share queue is ${this.queue.length}`)

      console.log('Video Ended:', this.queue.length)
    },
    changeStatus(status) {
      if (!this.value) {
        return
      }

      console.log('Change status', status)

      this.socketMediaShare.send(JSON.stringify({
        event_type: 'UPDATE_DATA',
        donation_id: this.queueData.id,
        state: status,
      }))
    },
    getVideoId(url) {
      if (!url) {
        return null
      }

      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      if (match && match[2].length === 11) {
        return match[2]
      }

      return null
    },
  },
}
</script>

<style scoped>
.player-container {
  max-width: 800px;
  margin: 0px auto;
}

.player-container .player-frame {
  position: relative;
  padding-bottom: 75%;
  height: 0px;
}

::v-deep .player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

::v-deep #player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.player-container .player-frame::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
}

.progress-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.progress-bar {
  transition: width 0.2s linear;
  height: 30px;
  text-align: center;
  display: flex;
  font-weight: 800;
  font-family: roboto;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0.05em rgba(0, 0, 0, 0.75);
}

.debug-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.debug-container > div {
  margin-bottom: 4px;
  font-family: monospace;
}

.debug-container > div > span {
  padding-left: 4px;
  padding-right: 4px;
  box-shadow: 0 0 0 #fff !important;
  background-color: #fff !important;
  box-decoration-break: clone !important;
}
</style>
