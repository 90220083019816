<template>
  <div class="container">
    <div v-if="serverDown">
      Server Down!
    </div>
    <div
      v-if="isDebugMode"
      class="debug-container"
    >
      <div
        v-for="(log, index) in logs"
        :key="`log-${index}`"
      >
        <span>{{ log.id }}.</span><span>{{ log.message }}</span>
      </div>
      <div><span>[DEBUG MODE]</span></div>
    </div>
    <transition-group name="transition">
      <Donation
        v-for="(item, index) in donations"
        :key="`donation-${item.id}`"
        :text-to-speech="textToSpeech"
        :donation="item"
        :show="index === donations.length - 1"
        @finish="showNextAlert"
        @log="pushLog"
      />
    </transition-group>
  </div>
</template>
<script>
import Donation from '@/components/Donation.vue'
import { loadScript } from 'vue-plugin-load-script'

export default {
  name: 'Home',
  components: {
    Donation,
  },
  data() {
    return {
      isAlertEnabled: true,
      textToSpeech: null,
      donations: [], // TODO: use single variable
      donationsQueue: [],
      variations: [],
      logs: [],
      userId: this.$route.params.userId,
      debug: false,
      fakerIndex: 0,
      loadedFonts: [],
      socket: null,
      connectAttempt: 0,
      serverDown: false,
      isDebugMode: this.$route.query.debug === 'true',
      lastLogId: 0,
    }
  },
  async mounted() {
    await this.loadUserSetting()

    if (!this.debug) {
      await this.subscribeChannel()
    } else {
      this.pushFakerDonations()
    }

    await this.loadFont()
  },
  beforeDestroy() {
    console.log('beforeDestroy', this.socket, this.socket.readyState === WebSocket.OPEN)
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.pushLog('Component destroyed. Closing websocket connection')
      console.log('close')
      this.socket.close(1000)
    }
  },
  methods: {
    pushLog(message) {
      if (!this.isDebugMode) {
        return
      }

      this.lastLogId += 1
      this.logs.splice(0, 0, {
        id: this.lastLogId,
        message,
      })

      if (this.logs.length >= 30) {
        this.logs.pop()
      }
    },
    async subscribeChannel() {
      console.log('readyState', this.socket ? this.socket.readyState : 'null')
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        return
      }

      const url = `${process.env.VUE_APP_SOCKET_BASE_URL}/v1/ws/users/media-share?page=alert-and-tts&source=streaming&user_id=${this.userId}`

      this.connectAttempt += 1

      // Define socket and attach it to our data object
      this.socket = await new WebSocket(url)
      this.socket.binaryType = 'arraybuffer'

      this.socket.onopen = () => {
        this.connectAttempt += 1
        this.serverDown = false
        console.info('Subscribed to channel')
        this.pushLog('Successfully subscribed to the channel')
      }

      this.socket.onclose = () => {
        if (this.connectAttempt === 1) {
          console.info('First attempt, server down.')
          this.pushLog('Can\'t connect to the channel at the first attempt, server is down.')
          this.serverDown = true
        }

        this.pushLog('Disconnected from the channel! reconnecting..')
        console.info('Disconnected! reconnecting..')

        this.subscribeChannel()
      }

      // When we receive a message from the server, we can capture it here in the onmessage event.
      this.socket.onmessage = async event => {
        const eventData = JSON.parse(event.data)
        const { eventType } = eventData
        const { data } = eventData

        if (eventType === 'CONTROLLER_CHANGES') {
          const { type } = data

          if (type === 'reload_stream_alert') {
            this.pushLog('Reloading widget..')
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            return
          }
        }

        if (eventType === 'UPDATE_STREAM_SETTINGS') {
          console.log('event data', data)
          await this.updateUserSettings(data)
        }

        // ignore if ping or if there is alert showing
        if (eventType !== 'ALERT_AND_TTS') {
          return
        }

        // load new settings
        await this.loadUserSetting()

        if (!this.isAlertEnabled) {
          return
        }

        // load unloaded fonts
        this.loadFont()

        // get first queue data
        if (data.mediaQueue) {
          this.pushLog(`New alert coming, from ${data.mediaQueue.donorName}, amount ${data.mediaQueue.donationAmount}, queue id #${data.mediaQueue.id.substr(0, 6)}`)
        } else {
          this.pushLog("New alert coming, no 'mediaQueue' data")
        }
        console.log('data', data)
        await this.pushQueue(data)
      }
    },
    async pushQueue(data) {
      const suitableVariant = this.getVariant(data.donationSetting)
      console.log('pushQueue suitableVariant', suitableVariant)

      // if suitable variant could not be found,
      // then do not show the alert
      if (!suitableVariant) {
        console.info('Can\'t add the new donation to queue, no suitable variant found')
        this.pushLog('can\'t add the new donation to queue, no suitable variant found')
        return
      }

      const donation = {
        // id: Math.random() * 10,
        settings: suitableVariant, // include variant settings
        ...data.donationSetting,
        ...data.mediaQueue,
      }

      // if no on going alerts, then show it immediately
      if (this.donations.length === 0) {
        this.donations.push(donation)
        return
      }

      // if there is on going alerts, then push it to queue
      this.donationsQueue.push(donation)

      console.log('New Stream Alert:', donation, 'Total stream alert queue:', this.donationsQueue.length + 1)
      this.pushLog(`Insert new stream alert to the local queue. Current total stream alert queue is ${this.donationsQueue.length + 1}`)

      // sort the queue from the biggest amount
      // this.donationsQueue = this.donationsQueue.sort((a, b) => parseFloat(b.donor_amount) - parseFloat(a.donor_amount))
    },
    async loadUserSetting() {
      console.log('load user setting called')
      let settings = null

      if (!this.debug) {
        const res = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/user-donation-setting`)
        settings = res.data.data
      } else {
        settings = {
          donationAlertImages: null,
          donationAlertSounds: null,
          isAlertEnabled: true,
          streamUrl: 'amet',
          textLayout: 'in_front',
          thankyouMessage: 'thank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for ',
          minDonationAmount: 2,
          messageTemplate: '{name} give you only {amount}!?',
          soundVolume: 10,
          textAnimation: 'pulse',
          alertDuration: 30,
          alertTextDelay: 0,
          fontSetting: {
            fontType: 'Lobster',
            fontSize: '40px',
            fontWeight: 400,
            textBaseColor: '#FFDE5D',
            textHighlightColor: '#EB3941',
          },
          alertAnimation: {
            in: 'fade-in-down-big',
            out: 'fade-out-down-big',
          },
          variations: [
            {
              name: 'variations one',
              donationAlertImages: {
                url: 'https://i.ibb.co/GFf3Zc0/NASB-spongebob-character-1.png',
              },
              donationAlertSounds: {
                url: 'https://cdn-staging.ganknow.com/media/1a603b09-9273-4e7f-bbaa-0a5806e8f5db/ES_Applause Cheer Small - SFX Producer-v60.aac',
              },
              isAlertEnabled: true,
              streamUrl: 'amet',
              textLayout: 'right',
              thankyouMessage: 'thank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for ',
              minDonationAmount: 30,
              messageTemplate: '{name} give you {amount}!',
              soundVolume: 100,
              textAnimation: 'wave',
              alertDuration: 30,
              alertTextDelay: 1,
              fontSetting: {
                fontType: 'Lobster',
                fontSize: '40px',
                fontWeight: 400,
                textBaseColor: '#000000',
                textHighlightColor: '#FFDE5D',
              },
              alertAnimation: {
                in: 'fade-in-down-big',
                out: 'fade-out-down-big',
              },
            },
            {
              name: 'variations two',
              donationAlertImages: {
                url: 'https://i.ibb.co/TvM8QDK/Pngtree-live-stream-alerts-for-twitch-6017087.png',
              },
              donationAlertSounds: null,
              isAlertEnabled: true,
              streamUrl: 'amet',
              textLayout: 'bottom',
              thankyouMessage: 'thank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for your donation. I hope it will be useful for me in the future, so that I can develop skills and talentsthank you for ',
              minDonationAmount: 50,
              messageTemplate: '{name} throw {amount} to you !',
              soundVolume: 10,
              textAnimation: 'tada',
              alertDuration: 30,
              alertTextDelay: 1,
              fontSetting: {
                fontType: 'Rubik Iso',
                fontSize: '40px',
                fontWeight: 400,
                textBaseColor: '#FFFFFF',
                textHighlightColor: '#36c19b',
              },
              alertAnimation: {
                in: 'fade-in-down-big',
                out: 'fade-out-down-big',
              },
            },
          ],
        }
      }

      settings.name = 'Main' // set main variant name

      this.isAlertEnabled = settings.isAlertEnabled
      this.textToSpeech = settings.textToSpeechEnabled
        ? settings.textToSpeech
        : null

      if (!this.isAlertEnabled) {
        return
      }

      // include main variant in variations
      let variations = [settings]

      // include other variations
      if (settings.variations && settings.variations.length) {
        const variationsData = settings.variations.map(variation => ({
          ...variation,
          textToSpeech: {
            includeTemplate: variation.ttsIncludeTemplate,
            minDonationToRead: variation.ttsMinDonationToRead || 0,
            voice: variation.ttsVoice || 'James',
            volume: variation.ttsVolume || 50,
          },
        }))
        variations = [
          ...variations,
          ...variationsData,
        ]
      }

      // sort from the biggest min alert
      this.variations = variations.sort((a, b) => b.minDonationAmount - a.minDonationAmount)
    },
    updateUserSettings(data) {
      const settings = data
      settings.name = 'Main' // set main variant name

      this.isAlertEnabled = settings.isAlertEnabled
      this.textToSpeech = settings.textToSpeechEnabled
        ? settings.textToSpeech
        : null

      if (!this.isAlertEnabled) {
        return
      }

      // include main variant in variations
      let variations = [settings]

      // include other variations
      if (settings.variations && settings.variations.length) {
        const variationsData = settings.variations.map(variation => ({
          ...variation,
          textToSpeech: {
            includeTemplate: variation.ttsIncludeTemplate,
            minDonationToRead: variation.ttsMinDonationToRead || 0,
            voice: variation.ttsVoice || 'James',
            volume: variation.ttsVolume || 50,
          },
        }))
        variations = [
          ...variations,
          ...variationsData,
        ]
      }

      // sort from the biggest min alert
      this.variations = variations.sort((a, b) => b.minDonationAmount - a.minDonationAmount)
      console.log('variation', this.variations)
    },
    async changeStatus({ id }, status) {
      if (!id) {
        return
      }

      console.log('Change status', status)

      this.socket.send(JSON.stringify({
        event_type: 'UPDATE_DATA',
        donation_id: id,
        state: status,
      }))
    },
    loadFont() {
      let fonts = this.variations
        .filter(item => item.fontSetting && item.fontSetting.fontType)
        .map(({ fontSetting }) => fontSetting.fontType)
        .filter(item => !this.loadedFonts.includes(item))

      // No font to load
      if (!fonts.length) {
        return
      }

      // push to loaded fonts
      this.loadedFonts.push(...fonts)

      // remove duplicate
      fonts = [...new Set(fonts)]

      loadScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js')
        // eslint-disable-next-line no-undef
        .then(() => WebFont.load({
          google: {
            families: fonts.map(item => `${item}:400,600`),
          },
        }))
    },
    async showNextAlert() {
      this.pushLog('Change current queue status to played.')
      // TODO: should directly get next alert from the response
      this.changeStatus(this.donations[0], 'PLAYED')

      // remove current alert
      this.donations.splice(0, 1)
      this.pushLog(`Remove current alert from the queue. Total stream alert queue is ${this.donationsQueue.length}`)

      console.log('donationsQueue', this.donationsQueue)
      if (this.donationsQueue.length) {
        const nextQueue = this.donationsQueue[0]
        this.donations.push(nextQueue)
        this.donationsQueue.splice(0, 1)
        this.pushLog(`Play the next stream alert, from ${nextQueue.donorName}, amount ${nextQueue.donationAmount}, queue id #${nextQueue.id.substr(0, 6)}`)
        console.log('Play next Stream Alert:', nextQueue, 'Total stream alert queue:', this.donationsQueue.length + 1)
      }
    },
    getVariant(item) {
      console.log('getVariant item', item)
      const variant = this.variations.find(itemVariant => {
        if (item.stream_alert_variation_test && item.stream_alert_variation_test.name) {
          return item.stream_alert_variation_test
          && itemVariant.name.toLowerCase() === item.stream_alert_variation_test.name.toLowerCase()
        }
        return (item.stream_alert_variation
          && itemVariant.name.toLowerCase() === item.stream_alert_variation.name.toLowerCase()) || (!item.stream_alert_variation && item.condition_filtered && itemVariant.name.toLowerCase() === item.condition_filtered.name.toLowerCase())
      })

      console.log('getVariant variant', variant)
      if (variant) {
        console.log('if variant', variant)
        return variant
      }

      return this.variations.find(itemVariant => itemVariant.name.toLowerCase() === 'main')
    },
    pushFakerDonations() {
      const donations = [
        {
          created_at: '2023-03-15T22:52:06.465843067Z',
          donor_name: null,
          donor_amount: '90000000000',
          donor_message: 'This is the only thing I have. But i hope you can use it for good things. Like helping other people to get rich.',
        },
        {
          created_at: '2023-03-15T22:52:06.465843067Z',
          donor_name: 'SquarePants',
          donor_amount: 40,
          donor_message: 'I am ready! I am ready!',
        },
        {
          created_at: '2023-03-15T22:52:06.465843067Z',
          donor_name: 'RichBoy',
          donor_amount: 40000,
          donor_message: null,
        },
      ]

      this.pushQueue(donations[this.fakerIndex])

      this.fakerIndex = this.fakerIndex === donations.length - 1
        ? 0 // reset to 0
        : this.fakerIndex + 1

      setTimeout(this.pushFakerDonations, 3000)
    },
  },
}
</script>

<style>
@import url('../assets/css/animations/all.css');
@import url('../assets/css/text-animations/all.css');

.container {
  position: relative;
  width: 100%;
  /*padding-top: 56.25%; !* 9:16 Aspect Ratio (divide 9 by 16 = 0.5625). Because 3:4 is to tall *!*/
  overflow: hidden;
  height: 100vh;
}

.debug-container {
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.debug-container > div {
  margin-bottom: 4px;
  font-family: monospace;
}

.debug-container > div > span {
  padding-left: 4px;
  padding-right: 4px;
  box-shadow: 0 0 0 #fff !important;
  background-color: #fff !important;
  box-decoration-break: clone !important;
}
</style>
