<template>
  <div class="qr-wrapper">
    <div
      v-if="qrData"
      class="qr-display"
      :class="qrData.title ? 'has-title' : ''"
    >
      <p
        v-if="qrData.title"
        class="qr-title"
      >
        {{ qrData.title }}
      </p>
      <div class="qr-image">
        <img
          v-if="qrData.image"
          :src="`data:image/png;base64,${qrData.image}`"
        >
      </div>
      <p
        class="qr-support"
      >
        <span>{{ `Support me at ${qrUrl.host}` }}</span>
        <span>{{ qrUrl.path }}</span>
      </p>
    </div>
  </div>

</template>

<script>
import { loadScript } from 'vue-plugin-load-script'

export default {

  data() {
    return {
      userId: this.$route.params.userId,
      qrData: undefined,
    }
  },
  computed: {
    qrUrl() {
      if (this.qrData && this.qrData?.url) {
        const url = new URL(this.qrData.url)
        const { hostname, pathname } = url

        return {
          host: `${hostname}/`,
          path: pathname.substring(1),
        }
      }

      return undefined
    },
  },
  async mounted() {
    await this.loadQrCode()
    await this.loadFont()
    this.subscribeChannel()
  },
  methods: {
    async loadQrCode() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_V2_BASE_URL}/user-content/profile/qr?owner_user_id=${this.userId}`)

      this.qrData = res.data
    },
    loadFont() {
      loadScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js')
        // eslint-disable-next-line no-undef
        .then(() => WebFont.load({
          google: {
            families: ['Noto+Sans:ital,wght@400,500'],
          },
        }))
    },
    async subscribeChannel() {
      const url = `${process.env.VUE_APP_SOCKET_BASE_URL}/v1/ws/users/media-share?page=donation_overlay&source=streaming&user_id=${this.userId}`

      // Define socket and attach it to our data object
      this.socket = await new WebSocket(url)
      this.socket.binaryType = 'arraybuffer'

      this.socket.onopen = () => {
        console.info('Subscribed to channel')
      }

      this.socket.onclose = () => {
        console.info('Disconnected! reconnecting..')
        this.subscribeChannel()
      }

      // When we receive a message from the server, we can capture it here in the onmessage event.
      this.socket.onmessage = async event => {
        const eventData = JSON.parse(event.data)
        const { eventType } = eventData

        if (eventType === 'UPDATE_QRPROFILE') {
          await this.loadQrCode()
        }

        if (!(event.data instanceof ArrayBuffer)) {
          return
        }

        await this.loadQrCode()
      }
    },
  },
}
</script>

<style>

.qr-wrapper {
  height: 100%;
  background-color: transparent;
  max-width: 512px;
  box-sizing: border-box;
}

.qr-wrapper > * {
  box-sizing: border-box;
}

.qr-display {
  background-color: #091C2D;
  font-family: "Noto Sans", sans-serif;
  width: 100%;
  height: 100%;
  padding: 8%;
  border-radius: 8px;
}

.qr-display.has-title {
  padding-top: 4%;
}

.qr-title,
.qr-support {
  color: #fff;
  font-size: 1.4em;
  margin: 0;
  text-align: center;
  transition: all 0.2s linear;
}

.qr-title {
  margin-bottom: 4%;
  font-weight: 500;
}
.qr-support {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  word-break: break-word;
}

.qr-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  /* Fallback */
  position: relative;
}

.qr-image::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.qr-image img {
  transition: all 0.2s linear;
  position: absolute;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
