<template>
  <div
    class="layout-in-front"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  >
    <div
      class="text"
      :class="{'fade-in show': textDelay !== '0s'}"
      :style="{
        fontSize,
        color: textColor,
        animationDelay: textDelay,
        animationDuration: textDelay !== '0s' ? '0.175s' : '0s',
        opacity: textDelay !== '0s' ? 0 : undefined,
      }"
    >
      <div>
        <!-- Title -->
        <div
          class="title"
          :style="{
            fontFamily,
            fontWeight,
          }"
        >
          <span
            v-for="(item, index) in titleWords"
            :key="`title-word-${index}`"
          >
            <span
              v-for="(letter, letterIndex) in item.letters"
              :key="`title-letter-${letterIndex}`"
              :style="{color: letter.textColor}"
              :class="letter.textAnimation"
            >{{ letter.value }}</span></span>
        </div>

        <!-- Message -->
        <div
          v-if="messageLetters"
          class="message"
          :style="{
            fontFamily,
          }"
        >
          <span
            v-for="(item, index) in messageLetters"
            :key="`message-letter-${index}`"
          >{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import props from '@/props/donation-layout'

export default {
  props,
}
</script>

<style>
.layout-in-front {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.layout-in-front .text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  text-shadow: 0.05em 0 0.125em rgba(0, 0, 0, 0.5);
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(-50%);
  white-space: break-spaces;
}

.layout-in-front .title > span {
  display: inline-block;
}

.layout-in-front .message {
  font-family: sans-serif;
  margin-top: 0.2em;
  font-size: 0.6em; /* n% of the user's selected size */
}
</style>
