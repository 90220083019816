var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-condensed",style:({ 'font-family': _vm.goalSettings.fontFamily })},[_c('div',{staticClass:"bar",style:({
      color: _vm.goalSettings.barTextColor,
      backgroundColor: _vm.goalSettings.barBackgroundColor,
      minHeight: ((_vm.goalSettings.barThickness) + "px"),
    })},[_c('div',{staticClass:"progress"},[_vm._v(" $"+_vm._s(_vm._f("number")(_vm.goalSettings.currentAmount,{ minimumFractionDigits: 2 }))+" ("+_vm._s(_vm.progressPercentage)+"%) ")]),_c('div',{staticClass:"sides"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.goalSettings.title)+" ")]),_c('div',{staticClass:"target-amount"},[_vm._v(" $"+_vm._s(_vm._f("number")(_vm.goalSettings.targetAmount,{ minimumFractionDigits: 2 }))+" ")])]),_c('div',{staticClass:"bar-progress",style:({
        width: (_vm.widthPercentage + "%"),
        backgroundColor: _vm.goalSettings.barColor,
      })})])])}
var staticRenderFns = []

export { render, staticRenderFns }