<template>
  <div>
    <component
      :is="component"
      v-if="isOverlayEnabled"
      :title="title"
      :target-amount="targetAmount"
      :font-family="fontFamily"
      :current-amount="currentAmount"
      :bar-background-color="barBackgroundColor"
      :bar-color="barColor"
      :bar-text-color="barTextColor"
      :text-color="textColor"
      :bar-thickness="barThickness"
    />
  </div>
</template>

<script>
import DonationGoalCondensed from '@/components/DonationGoalCondensed.vue'
import DonationGoalStandard from '@/components/DonationGoalStandard.vue'
import { loadScript } from 'vue-plugin-load-script'

export default {
  components: {
    DonationGoalCondensed,
    DonationGoalStandard,
  },
  data() {
    return {
      userId: this.$route.params.userId,
      debug: false,
      fakerIndex: 0,

      settings: {},
      loadedFonts: [],

      title: '',
      targetAmount: 0,
      currentAmount: 0,

      isOverlayEnabled: false,

      // theme
      layout: '',
      fontFamily: '',
      barBackgroundColor: '',
      barColor: '',
      barTextColor: '',
      textColor: '',
      barThickness: 0,
    }
  },
  computed: {
    component() {
      switch (this.layout) {
        case 'standard': return 'DonationGoalStandard'
        case 'condensed': return 'DonationGoalCondensed'
        default: return 'DonationGoalStandard'
      }
    },
  },
  async mounted() {
    await this.loadUserSetting()

    if (!this.debug) {
      await this.subscribeChannel()
    } else {
      this.pushFakerDonations()
    }

    this.loadFont()
  },
  methods: {
    async loadUserSetting() {
      if (!this.debug) {
        const res = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/user-donation-overlay/${this.userId}`)
        this.settings = res.data.data
        if (this.settings.goalsDonation?.length > 0) {
          // filter completed goal
          const filtered = this.settings.goalsDonation.filter(obj => !obj.isComplete)
          if (filtered.length) {
          // get latest goal if incomplete goal is more than 0
            let latestDate = null

            // Iterate over the array
            for (let i = 0; i < filtered.length; i += 1) {
              const obj = filtered[i]
              const createdAt = new Date(obj.createdAt)
              if (!latestDate || createdAt > latestDate) {
                latestDate = createdAt
                this.settings.goalsDonation = [obj]
              }
            }
          } else {
            this.settings.goalsDonation = null
          }
        } else {
          this.settings.goalsDonation = null
        }
      } else {
        this.settings = {
          id: '70d37d8f-d8ce-4cc6-a393-417aa5725bfd',
          userID: 'ce04d1c0-8175-4665-ae65-1b69fd3c9cdb',
          donationUrl: 'https://ganknow.com/overlay/test',
          isOverlayEnabled: true,
          isDonationEnabled: true,
          barThickness: 40,
          layout: 'standard',
          barTextColor: '#c13333',
          font: 'Lobster Two',
          backgroundColor: '#D9D9D9',
          barColor: '#c13333',
          textColor: '#2f2ced',
          barBackgroundColor: '#cc9ff9',
          goalsDonation: [
            {
              id: '03f8eaec-a235-4c70-8749-6b47442721f3',
              createdAt: '2023-04-04T02:37:48Z',
              updatedAt: '2023-04-11T02:19:36Z',
              title: 'Goal',
              description: 'Goal',
              userID: 'ce04d1c0-8175-4665-ae65-1b69fd3c9cdb',
              balance: 0,
              firstBalance: 0,
              target: 10,
              isActive: true,
              isComplete: false,
              isPublishTarget: true,
              supporterCount: 0,
            },
          ],
        }
      }

      // if user doesn't enabled the overlay or doesn't set any goal
      this.isOverlayEnabled = this.settings.isOverlayEnabled
        && this.settings.isDonationEnabled
        && this.settings.goalsDonation
        && this.settings.goalsDonation.length
        && this.settings.goalsDonation[0].isActive

      if (!this.isOverlayEnabled) {
        return
      }

      this.title = this.settings.goalsDonation[0].title
      this.targetAmount = this.settings.goalsDonation[0].target
      this.currentAmount = this.settings.goalsDonation[0].balance

      this.layout = this.settings.layout || 'standard' // standard, condensed
      this.fontFamily = this.settings.font || 'Inter'
      this.barBackgroundColor = this.settings.barBackgroundColor || '#FFFFFF'
      this.barColor = this.settings.barColor || '#FF4E58'
      this.barTextColor = this.settings.barTextColor || '#04101B'
      this.textColor = this.settings.textColor || '#FFFFFF'
      this.barThickness = this.settings.barThickness || 40
    },
    updateUserSetting(data) {
      this.settings = data
      // if user doesn't enabled the overlay or doesn't set any goal
      this.isOverlayEnabled = this.settings.isOverlayEnabled
        && this.settings.isDonationEnabled
        && this.settings.goalsDonation
        && this.settings.goalsDonation.length
        && this.settings.goalsDonation[0].isActive

      if (!this.isOverlayEnabled) {
        return
      }

      if (this.fontFamily !== this.settings.font) {
        this.loadFont()
      }

      this.title = this.settings.goalsDonation[0].title
      this.layout = this.settings.layout || 'standard' // standard, condensed
      this.fontFamily = this.settings.font || 'Inter'
      this.barBackgroundColor = this.settings.barBackgroundColor || '#FFFFFF'
      this.barColor = this.settings.barColor || '#FF4E58'
      this.barTextColor = this.settings.barTextColor || '#04101B'
      this.textColor = this.settings.textColor || '#FFFFFF'
      this.barThickness = this.settings.barThickness || 40
    },
    updateGoalDonations(data) {
      if (data.goalsDonation?.length > 0) {
        // filter completed goal
        const filtered = data.goalsDonation.filter(obj => !obj.isComplete)

        if (filtered.length) {
          // get latest goal if incomplete goal is more than 0
          let latestDate = null

          // Iterate over the array
          for (let i = 0; i < filtered.length; i += 1) {
            const obj = filtered[i]
            const createdAt = new Date(obj.createdAt)
            if (!latestDate || createdAt > latestDate) {
              latestDate = createdAt
              this.settings.goalsDonation = [obj]
            }
          }

          this.title = this.settings.goalsDonation[0].title
          this.targetAmount = this.settings.goalsDonation[0].target
          this.currentAmount = this.settings.goalsDonation[0].balance
        } else {
          this.settings.goalsDonation = null
        }
      }
    },
    loadFont() {
      // if font loaded already
      if (this.loadedFonts.includes(this.fontFamily)) {
        return
      }

      // push to loaded fonts
      this.loadedFonts.push(this.fontFamily)

      loadScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js')
        // eslint-disable-next-line no-undef
        .then(() => WebFont.load({
          google: {
            families: [`${this.fontFamily}:400,600`],
          },
        }))
    },
    async subscribeChannel() {
      const url = `${process.env.VUE_APP_SOCKET_BASE_URL}/v1/ws/users/media-share?page=donation_overlay&source=streaming&user_id=${this.userId}`

      // Define socket and attach it to our data object
      this.socket = await new WebSocket(url)
      this.socket.binaryType = 'arraybuffer'

      this.socket.onopen = () => {
        console.info('Subscribed to channel')
      }

      this.socket.onclose = () => {
        console.info('Disconnected! reconnecting..')
        this.subscribeChannel()
      }

      // When we receive a message from the server, we can capture it here in the onmessage event.
      this.socket.onmessage = async event => {
        const eventData = JSON.parse(event.data)
        const { eventType, notificationType } = eventData
        const { data } = eventData

        if (eventType === 'UPDATE_DONATION_SETTING') {
          this.updateUserSetting(data)
        }

        console.log('notificationType', notificationType)

        if (eventType === 'DONATION_OVERLAY' && notificationType === 'DONATION OVERLAY UPDATE') {
          console.log('called update goal')
          this.updateGoalDonations(data)
        }

        // validate format
        if (!(event.data instanceof ArrayBuffer)) {
          return
        }

        // load new settings
        await this.loadUserSetting()

        if (!this.isOverlayEnabled) {
          return
        }

        // load unloaded fonts
        this.loadFont()
      }
    },
    pushFakerDonations() {
      const donations = [
        {
          donor_amount: 1,
        },
        {
          donor_amount: 2,
        },
        {
          donor_amount: 1,
        },
      ]

      this.currentAmount += donations[this.fakerIndex].donor_amount

      this.fakerIndex = this.fakerIndex === donations.length - 1
        ? 0 // reset to 0
        : this.fakerIndex + 1

      setTimeout(this.pushFakerDonations, 1000)
    },
  },
}
</script>

<style>

</style>
